const env = {
  WS_URL: 'wss://stagingapi.trally.com/',
  API_URL: 'https://stagingapi.trally.com/api',
  STRIPE_PK:'pk_test_51HcveRHKqVacImEeRTwb3mId4NxZmENqiy97PQ2BlzMQ9CvgpPi056Rrv4RDwKZJHUD8bGjipX3He6vZ3nCULgdT00LMN5jGP2',
  MAPS_API: 'AIzaSyABaz_rqVEWyToREgpZ9hsodGa8WIdmr80',
  SCREEN_WIDTH: 680,
  MINIMUM_AMOUNT: 10,
  PRIVACY_URL:
    'https://www.trally.com/terms-conditions/privacy-policy',
  TOC_URL:
    'https://www.trally.com/terms-conditions/app-terms-and-condition',
  HELP_URL: 'https://www.trally.com/',
  ABOUTUS_URL: 'https://www.trally.com/about-us',
  CONTACTUS_URL: 'https://www.trally.com/contact-us',
  FAQ_URL: 'https://www.trally.com/contact-us#faq',
  FEEDBACK_URL: 'https://www.loomio.org/trally/',
  LINKEDIN: 'https://www.linkedin.com/company/trallytours/',
  FACEBOOK: 'https://www.facebook.com/trallytours',
  YOUTUBE: 'https://www.youtube.com/channel/UC2pZB1IOdSz4wPrznBbUMIQ',
  PLAYSTORE_URL:
    'https://play.google.com/store/apps/details?id=com.trally',
  APPSTORE_URL: 'https://apps.apple.com/us/app/trally/id1525750258',
  IOS_VERSION: '1.7.100',
  ANDROID_VERSION: '1.3.20'
}

export default env
